<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col
          v-for="(item,index) in respData"
          :key="index"
          cols="4"
        >
          <b-card
            :bg-variant="checkSelected(item._id) === true ?'primary':''"
            :text-variant="checkSelected(item._id) === true ?'white':''"
            no-body
            img-top
            tag="article"
            class="overflow-hidden"
            @click="selectCard(item._id)"
          >
            <b-row no-gutters>
              <b-col md="12">
                <b-card-img
                  :src="item.images.length>0 ?item.images[0] : 'https://picsum.photos/600/300/?image=25'"
                  :alt="item._id"
                  style="max-height: 150px;"
                  class="rounded-0"
                />
              </b-col>
              <b-col md="12">
                <b-row>
                  <b-col
                    cols="6"
                    class="d-flex align-items-center justify-content-start p-2"
                  >
                    <p>{{ showFromCurrentLanguage(item.name) }}</p>
                    <p>{{ showFromCurrentLanguage(item.detail) }}</p>

                  </b-col>
                  <b-col
                    cols="6"
                    class="d-flex align-items-center justify-content-end p-2"
                  >
                    <b-badge
                      v-if="checkSelected(item._id) === true"
                      variant="success"
                    >
                      {{ $t('Actived') }}
                      <feather-icon
                        icon="CheckIcon"
                      /></b-badge>

                    <feather-icon
                      v-if="checkSelected(item._id) === false"
                      icon="XIcon"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-card-footer><b-row>
              <b-col cols="6">
                <small>{{ $t('Created') }}: {{ item.created | formatDate }}</small>
              </b-col>
              <b-col cols="6">
                <small>{{ $t('Updated') }}: {{ item.updated | formatDate }}</small>
              </b-col>
            </b-row></b-card-footer>

          </b-card>
        </b-col>
      </b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="d-flex"
        @click="saveData"
      >
        <feather-icon
          icon="SaveIcon"
        />
        <!-- <span class="align-middle">Add Users</span> -->
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge,
  BCard, BCardFooter, BButton, BCardText, BRow, BCol,
  BImg,
  BCardImg,
  BAvatar,
  BFormInput, BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/promotion'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'

const STORE_MODULE_NAME = 'promotion'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'

export default {
  components: {
    BBadge,

    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BCardImg,
    BCardFooter,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      selected: [],
      show: false,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respDataService != null ? store.state[STORE_MODULE_NAME].respDataService.data : []
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getService`, { id: this.$route.params.id })
    // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getServiceSelected`, { id: this.$route.params.id })
    // eslint-disable-next-line no-unused-vars
      .then(result => {
        const dataSelected = result.data.data.map(e => e.queueServiceId)
        this.selected = dataSelected
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA }
  },
  methods: {
    checkSelected(id) {
      const index = this.selected.findIndex(e => e === id)
      if (index > -1) {
        return true
      }
      return false
    },
    selectCard(id) {
      const index = this.selected.findIndex(e => e === id)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    saveData() {
      this.show = true

      const obj = {
        id: this.$route.params.id,
        selected: this.selected,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/updateServiceSelected`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          this.show = false

          // this.showToast('danger', 'bottom-right')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    .v-select-custom {
      display: inline-block;
      width: 50;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .b-col-custom {
      margin-top: 0.5rem;
    }
    </style>
